/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sesCall = /* GraphQL */ `
  query SesCall($msg: CustomerInput) {
    sesCall(msg: $msg)
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      email
      phone
      start_date
      start_time
      course
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        start_date
        start_time
        course
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseRun = /* GraphQL */ `
  query GetCourseRun($id: ID!) {
    getCourseRun(id: $id) {
      id
      ssgRunID
      referenceNumber
      title
      startDate
      endDate
      openingRegistrationDate
      closingRegistrationDate
      scheduleInfo
      modeOfTraining
      courseVacancy
      courseAdminEmail
      venueStreet
      venueBuilding
      venueFloor
      venueBlock
      venueUnit
      venueRoom
      venuePostalCode
      sessions {
        ssgSessionID
        startDate
        endDate
        startTime
        endTime
        modeOfTraining
        venueStreet
        venueBuilding
        venueFloor
        venueBlock
        venueUnit
        venueRoom
        venuePostalCode
      }
      trainers {
        ssgTrainerID
        name
        email
        trainerType
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourseRuns = /* GraphQL */ `
  query ListCourseRuns(
    $filter: ModelCourseRunFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseRuns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ssgRunID
        referenceNumber
        title
        startDate
        endDate
        openingRegistrationDate
        closingRegistrationDate
        scheduleInfo
        modeOfTraining
        courseVacancy
        courseAdminEmail
        venueStreet
        venueBuilding
        venueFloor
        venueBlock
        venueUnit
        venueRoom
        venuePostalCode
        sessions {
          ssgSessionID
          startDate
          endDate
          startTime
          endTime
          modeOfTraining
          venueStreet
          venueBuilding
          venueFloor
          venueBlock
          venueUnit
          venueRoom
          venuePostalCode
        }
        trainers {
          ssgTrainerID
          name
          email
          trainerType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
